import React, { useEffect, useState, useRef } from 'react';
import { Form, Modal, Spinner, ButtonGroup } from 'react-bootstrap';
import { Button, Card, CardBody, Row, Col } from 'reactstrap';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Backspace, Save2Fill, Trash } from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import * as Yup from 'yup';
import { Formik } from 'formik';

import common from '../../commonData';
import dateformat from 'dateformat';
import Table from 'views/tableaddedit';

var count = 0;

function AddEntre({ id, setShowAdd, show, setIsSave, setDatas, setMsg, setMsgravity, stateParent, setActualise, actualise }) {
    var ladate = new Date();
    const [codeart, setCodeart] = useState([]);
    const [article, setArticle] = useState([]);
    const [fournisseur, setFournData] = useState([]);
    const [listeSelect, setListeSelect] = useState(stateParent.ligneentree);
    const [load, setLoad] = useState(false);
    const history = useHistory();
    const fourn = useRef(null);
    const [states, setState] = useState({
        dateentr:
            stateParent.dateentr || dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd'),
        datearriv: stateParent.datearriv,
        arrivage: stateParent.arrivage,
        remarque: stateParent.remarque,
        fournisseur_id: stateParent.fournisseur_id
    });
    const { dateentr, datearriv, arrivage, remarque, fournisseur_id } = states;

    useEffect(() => {
        if (!localStorage.getItem('user-info')) {
            history.push('/login');
        }
        (async function anyNameFunction() {
            setLoad(true);
            const getarticle = Axios.get(common.ipapi + '/api/article/indexall');
            const getFourn = Axios.get(common.ipapi + '/api/fournisseur/all');
            await Axios.all([getarticle, getFourn])
                .then(
                    Axios.spread((...responses) => {
                        setArticle(responses[0].data);
                        setFournData(responses[1].data);
                        setLoad(false);
                    })
                )
                .catch((errors) => {
                    setLoad(false);
                });
        })();
    }, []);

    const EditQte = ({ value: initialValue, row: { index } }) => {
        const [value, setValue] = React.useState(initialValue);
        const onChange = (e) => {
            setValue(e.target.value);
            listeSelect[index].quantite = e.target.value;
            listeSelect[index].tht =
                listeSelect[index].remise != 0
                    ? listeSelect[index].prixachat * listeSelect[index].quantite -
                      listeSelect[index].prixachat * listeSelect[index].quantite * (listeSelect[index].remise / 100)
                    : listeSelect[index].prixachat * listeSelect[index].quantite;
            listeSelect[index].ismodif = true;
        };
        const onBlur = (e) => {
            const cloneProducts = [...listeSelect];
            setListeSelect(cloneProducts);
        };
        React.useEffect(() => {
            setValue(initialValue);
        }, [initialValue]);
        return (
            <Form.Control
                type="number"
                className="prix"
                aria-describedby="inputGroupPrepend"
                size="sm"
                min={1}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />
        );
    };
    const Editprixachat = ({ value: initialValue, row: { index } }) => {
        const [value, setValue] = React.useState(initialValue);
        const onChange = (e) => {
            setValue(e.target.value);
            listeSelect[index].prixachat = e.target.value;
            listeSelect[index].tht =
                listeSelect[index].remise != 0
                    ? listeSelect[index].prixachat * listeSelect[index].quantite -
                      listeSelect[index].prixachat * listeSelect[index].quantite * (listeSelect[index].remise / 100)
                    : listeSelect[index].prixachat * listeSelect[index].quantite;
            listeSelect[index].ismodif = true;
            setListeSelect(listeSelect);
        };
        const onBlur = (e) => {
            const cloneProducts = [...listeSelect];
            setListeSelect(cloneProducts);
        };

        React.useEffect(() => {
            setValue(initialValue);
        }, [initialValue]);
        return (
            <Form.Control
                type="number"
                className="prix"
                aria-describedby="inputGroupPrepend"
                size="sm"
                min={0}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />
        );
    };
    const EditRemise = ({ value: initialValue, row: { index } }) => {
        const [value, setValue] = React.useState(initialValue);
        const onChange = (e) => {
            setValue(e.target.value);
            listeSelect[index].remise = e.target.value;
            listeSelect[index].tht =
                e.target.value != 0
                    ? listeSelect[index].prixachat * listeSelect[index].quantite -
                      listeSelect[index].prixachat * listeSelect[index].quantite * (e.target.value / 100)
                    : listeSelect[index].prixachat * listeSelect[index].quantite;
            listeSelect[index].ismodif = true;
        };
        const onBlur = (e) => {
            const cloneProducts = [...listeSelect];
            setListeSelect(cloneProducts);
        };

        React.useEffect(() => {
            setValue(initialValue);
        }, [initialValue]);
        return (
            <Form.Control
                type="number"
                className="prix"
                aria-describedby="inputGroupPrepend"
                size="sm"
                min={0}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />
        );
    };

    const columns = [
        {
            Header: 'Code art.',
            accessor: 'qrbarre'
        },
        {
            Header: 'Descr.art.',
            accessor: 'descriptionart'
        },
        {
            Header: 'Quantite',
            accessor: 'quantite',
            Cell: EditQte
        },
        {
            Header: 'Prix achat',
            accessor: 'prixachat',
            Cell: Editprixachat
        },
        {
            Header: 'Remise',
            accessor: 'remise',
            Cell: EditRemise
        },
        {
            Header: 'Mont. HT',
            accessor: 'tht'
        },
        {
            Header: '#',
            accessor: '[editButton]',
            Cell: (row) => (
                <ButtonGroup aria-label="Basic example">
                    <Trash onClick={() => onDelete(row)} color="red" size={15} />
                </ButtonGroup>
            )
        }
    ];

    function onDelete(row) {
        if (row.row.original.id === 0) {
            const del = listeSelect.filter((d) => d.article_id !== row.row.original.article_id);
            setListeSelect(del);
        } else {
            if (window.confirm('Voulez-vous supprimer cette ligne ? ' + row.row.original.id)) {
                Axios.delete(common.ipapi + '/api/entrees/destroyligne/' + row.row.original.id).then((response) => {
                    if (response.data.status) {
                        const del = listeSelect.filter((d) => d.id !== row.row.original.id);
                        setListeSelect(del);
                        setMsg(response.data.msg);
                        setMsgravity('info');
                        setIsSave(true);
                        count++;
                        setLoad(false);
                    } else {
                        setMsg(response.data.msg);
                        setIsSave(true);
                        setMsgravity('danger');
                        setLoad(false);
                    }
                });
            } else {
                setLoad(false);
            }
        }
    }
    function saveEntree(values, setSubmitting) {
        try {
            if (listeSelect.length == 0) {
                setMsg('Veuillez sélectionner les produits');
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
            setLoad(true);
            setSubmitting(true);
            const filteredProduct = listeSelect.filter((product, index) => {
                return product.quantite !== '0' && product.ismodif;
            });
            var formData = new FormData();
            formData.append('dateentr', values.dateentr);
            formData.append('datearriv', values.datearriv);
            formData.append('fournisseur_id', values.fournisseur_id);
            formData.append('arrivage', values.arrivage);
            formData.append('remarque', values.remarque);
            formData.append('codeart', JSON.stringify(filteredProduct));
            formData.append('user_id', common.userid);
            Axios({
                url: id ? common.ipapi + '/api/entrees/update/' + id : common.ipapi + '/api/entrees/create',
                method: 'POST',
                headers: {
                    'Content-Type': 'form-data'
                },
                data: formData
            })
                .then((res) => {
                    if (res.status !== 200) {
                        setMsg('Veuillez vérifier votre connexion internet');
                        setMsgravity('info');
                        setIsSave(false);
                        setLoad(false);
                        setSubmitting(false);
                        return;
                    }
                    if (res.data.status === 1) {
                        setCodeart([]);
                        fourn.current.clear();
                        setMsg(res.data.msg);
                        setIsSave(true);
                        setMsgravity('info');
                        setListeSelect([]);
                        count++;
                        setActualise(!actualise);
                        setLoad(false);
                        setSubmitting(false);
                        if (id) {
                            setTimeout(handleClose, 600);
                        }
                    } else {
                        if (res.data.msg == 23000) {
                            setMsg('Veuillez remplir tous les champs');
                        } else {
                            setMsg(res.data.msg);
                        }
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                        setSubmitting(false);
                    }
                })
                .catch((error) => {
                    setMsg('Une erreur à eu lieu lors de la création');
                    setIsSave(false);
                    setMsgravity('danger');
                    setLoad(false);
                    setSubmitting(false);
                });
        } catch (error) {
            setMsg('Veuillez vérifier votre connexion internet');
            setIsSave(true);
            setMsgravity('danger');
            setLoad(false);
            setSubmitting(false);
        }
    }

    const handleClose = () => {
        count = 0;
        setShowAdd(false);
        setIsSave(false);
    };

    const handleOnChange = (selected) => {
        setCodeart(selected);

        if (selected.length > 0) {
            const lastSelectedItem = selected[selected.length - 1];
            lastSelectedItem.quantite = 1; // Or set default quantity as needed

            const itemExists = listeSelect.some((item) => item.article_id === lastSelectedItem.id);

            if (!itemExists) {
                const newItem = {
                    id: 0, // Consider if this ID needs to be unique
                    article_id: lastSelectedItem.id,
                    qrbarre: lastSelectedItem.qrbarre,
                    quantite: 1,
                    prixachat: 0,
                    descriptionart: lastSelectedItem.descriptionart,
                    ismodif: true,
                    remise: 0,
                    tht: 0
                };

                setListeSelect([...listeSelect, newItem]); // More efficient update
                setCodeart([]); // Clear typeahead input
                // setFieldValue('codeart', []); // Clear form field
            } else {
                setIsSave(true);
                setMsgravity('warning');
                setMsg('Déjà ajouté à la liste');
                setCodeart([]); // Clear typeahead input
                // setFieldValue('codeart', []); // Clear form field
            }
        }
    };

    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="grow" variant="warning" />
                </Modal.Body>
            </Modal>

            <Formik
                initialValues={{
                    arrivage: arrivage,
                    dateentr:
                        dateentr || dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd'),
                    fournisseur_id: fournisseur_id,
                    user_id: common.userid,
                    datearriv: datearriv,
                    remarque: remarque,
                    submit: null
                }}
                // enableReinitialize
                validationSchema={Yup.object().shape({
                    fournisseur_id: Yup.number().required(common.msg),
                    dateentr: Yup.date().required(common.msg)
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        saveEntree(values, setSubmitting);
                    } catch (err) {
                        setMsg('Une erreur à eu lieu');
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setFieldTouched }) => (
                    <Modal show={show} fullscreen={true} onHide={handleClose}>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header>
                                {id ? 'MODIFICATION ENTREE DE STOCK' : 'FAIRE UNE ENTREE DE STOCK'}
                                <Backspace onClick={handleClose} color="red" size={30} />
                            </Modal.Header>
                            <Modal.Body>
                                <div id="lightbody">
                                    <Row>
                                        <Col md={4}>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={3}>
                                                    Date entrées:
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Form.Control
                                                        id="dateentr"
                                                        type="date"
                                                        placeholder="Date entrées"
                                                        aria-describedby="inputGroupPrepend"
                                                        required
                                                        size="sm"
                                                        value={values.dateentr}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={3}>
                                                    N° conteneur:
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Form.Control
                                                        id="arrivage"
                                                        type="text"
                                                        placeholder="N° conteneur"
                                                        aria-describedby="inputGroupPrepend"
                                                        required
                                                        size="sm"
                                                        value={values.arrivage}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={3}>
                                                    Date arrivage:
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Form.Control
                                                        id="datearriv"
                                                        type="date"
                                                        placeholder="Date arrivage"
                                                        aria-describedby="inputGroupPrepend"
                                                        required
                                                        size="sm"
                                                        value={values.datearriv}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={3}>
                                                    Fournisseur:
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Typeahead
                                                        id="fournisseur_id"
                                                        ref={fourn}
                                                        multiple={false}
                                                        onChange={(selected) => {
                                                            const value = selected.length > 0 ? selected[0].id : 0;
                                                            setFieldValue('fournisseur_id', value);
                                                        }}
                                                        onInputChange={(text, event) => setFieldValue('fournisseur_id', text)}
                                                        onBlur={(e) => setFieldTouched('fournisseur_id', true)}
                                                        labelKey="nomfourn"
                                                        options={fournisseur}
                                                        selected={fournisseur.slice(
                                                            fournisseur.findIndex((element) => element.id === values.fournisseur_id),
                                                            fournisseur.findIndex((element) => element.id === values.fournisseur_id) + 1
                                                        )}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={3}>
                                                    Remarques:
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Form.Control
                                                        id="remarque"
                                                        as="textarea"
                                                        placeholder="Remarques"
                                                        aria-describedby="inputGroupPrepend"
                                                        cols={10}
                                                        value={values.remarque}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col md={8}>
                                            <Card>
                                                <CardBody>
                                                    <Form.Group as={Col} md="12">
                                                        <Form.Label>Sélectionner vos articles à rentrer en stock</Form.Label>
                                                        <Typeahead
                                                            id="my-typeahead-id"
                                                            labelKey={(option) =>
                                                                `${option.famille} > ${option.qrbarre} > ${
                                                                    option.descriptionart || option.descriptionartfr
                                                                } > qte (${option.qtestock})`
                                                            }
                                                            filterBy={['descriptionartfr']} // Or ['descriptionart', 'descriptionartfr', 'qrbarre'] if you want to filter by all
                                                            selected={codeart}
                                                            options={article}
                                                            disabled={load}
                                                            onChange={handleOnChange}
                                                            onInputChange={(text) => setFieldValue('codeart', text)}
                                                            onBlur={() => setFieldTouched('codeart', true)}
                                                            // clearButton // Useful for clearing the input
                                                            // highlightOnlyResult // If you want to highlight just the first result
                                                            // minLength={2} // Set a minimum length before suggestions appear
                                                        />
                                                    </Form.Group>

                                                    <Table columns={columns} data={listeSelect} />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="ml-auto">
                                    <ButtonGroup size="sm">
                                        <Button color="danger" onClick={handleClose}>
                                            <Backspace color="#000" size={20} />
                                        </Button>
                                        <Button type="submit" variant="ligth" disabled={isSubmitting}>
                                            {isSubmitting ? (
                                                <Spinner animation="border" variant="success" />
                                            ) : (
                                                <Save2Fill color="#807f7e" size={20} />
                                            )}{' '}
                                            Enregister
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                )}
            </Formik>
        </>
    );
}

export default AddEntre;
