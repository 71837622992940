import React, { useEffect, useState, useRef } from 'react';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, InputGroup, Spinner, Modal, Card, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import { Button, ButtonGroup, Row, Col } from 'reactstrap';
import Axios from 'axios';
import { Backspace, PlusCircle, Save2Fill, Trash, TriangleHalf } from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import common from '../../commonData';
import Table from '../../tableaddedit';
import AddClient from '../clients/addClient';

var count = 0;

function AddSortie({ id, setShowAdd, show, setIsSave, setMsg, setMsgravity, actualise, setActualise, state }) {
    const [codeart, setCodeart] = useState([]);
    const [clients, setClients] = useState([]);
    const [articlesBis, setListeArticleBis] = useState([]);
    const [familles, setfamilles] = useState([]);
    const [articleListe, setListeArticle] = useState([]);
    const [load, setLoad] = useState(false);
    const [qtesup, setQtesup] = useState(false);
    const [qtesupmsg, setQtesupmsg] = useState(false);
    const typeaheadModel = useRef(null);
    const [famille_id, setIdFamille] = useState(0);
    const [showAddclient, setShowAddclient] = useState(false);
    const [addclient, setAddclient] = useState(false);
    const [listeSelect, setListeSelect] = useState(state.lignesort);
    const [states, setState] = useState({
        demandeur: state.demandeur,
        engin: state.engin,
        nobon: state.nobon,
        datesort: state.datesort,
        client_id: state.client_id
    });
    useEffect(() => {
        getListeArticle();
        getClientAndFamille();
    }, [id]);

    function getListeArticle() {
        setLoad(true);
        Axios.get(common.ipapi + '/api/article/indexallsup')
            .then((responses) => {
                setListeArticle(responses.data);
                setListeArticleBis(responses.data);
                setLoad(false);
            })
            .catch((errors) => {
                setLoad(false);
            });
    }

    async function getClientAndFamille() {
        setLoad(true);
        const getCli = Axios.get(common.ipapi + '/api/client/indexmin');
        const getFamille = Axios.get(common.ipapi + '/api/famille/indexstep');

        await Axios.all([getCli, getFamille])
            .then(
                Axios.spread((...response) => {
                    setClients(response[0].data);
                    setfamilles(response[1].data);
                    setLoad(false);
                })
            )
            .catch((errors) => {
                setLoad(false);
            });
    }

    function setFamilleId(d) {
        setLoad(true);
        setIdFamille(d.length > 0 ? d[0].id : 0);
        if (d.length > 0) {
            const cloneProducts = [...articlesBis];
            const res = cloneProducts.filter((element) => element.famille_id === d[0].id);
            setListeArticle(res);
        } else {
            setListeArticle(articlesBis);
        }
        setLoad(false);
    }

    const Editableqte = ({ value: initialValue, row: { index }, column: { id } }) => {
        const [value, setValue] = React.useState(initialValue);
        const onChange = (e) => {
            setValue(e.target.value);
        };
        const onBlur = () => {
            listeSelect[index].quantite = parseFloat(value);
        };
        React.useEffect(() => {
            setValue(initialValue);
        }, [initialValue]);
        return (
            <Form.Control
                type="number"
                className="prix"
                aria-describedby="inputGroupPrepend"
                required
                size="sm"
                min={1}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />
        );
    };

    const EditableCellart = ({ value: initialValue, row: { index } }) => {
        const [value, setValue] = React.useState(initialValue);
        const onChange = (e) => {
            setValue(e.target.value);
        };
        const onBlur = () => {
            listeSelect[index].descriptionart = value;
        };
        React.useEffect(() => {
            setValue(initialValue);
        }, [initialValue]);
        return (
            <Form.Control
                type="text"
                className="prestation"
                aria-describedby="inputGroupPrepend"
                required
                size="sm"
                min="1"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />
        );
    };

    const columns = [
        {
            Header: 'Code art.',
            accessor: 'qrbarre'
        },
        {
            Header: 'Descr.art.',
            accessor: 'descriptionart',
            Cell: EditableCellart
        },
        {
            Header: 'Qté',
            accessor: 'quantite',
            Cell: Editableqte
        },
        {
            Header: '#',
            accessor: '[editButton]',
            Cell: (row) => (
                <ButtonGroup aria-label="Basic example">
                    <Trash onClick={() => onDelete(row)} color="red" size={15} />
                </ButtonGroup>
            )
        }
    ];

    function onDelete(row) {
        setLoad(true);
        if (row.row.original.id === 0) {
            const del = listeSelect.filter((d) => d.article_id !== row.row.original.article_id);
            setListeSelect(del);
            count++;
            setMsg('Ligne supprimée');
            setMsgravity('info');
            setIsSave(true);
            setLoad(false);
            return true;
        }
        if (window.confirm('Voulez-vous supprimer cet article ? ' + row.row.original.id)) {
            Axios.delete(common.ipapi + '/api/sortie/destroyligne/' + row.row.original.id).then((response) => {
                if (response.data.status) {
                    const del = listeSelect.filter((d) => d.id !== row.row.original.id);
                    setListeSelect(del);
                    setMsg(response.data.msg);
                    setMsgravity('info');
                    setIsSave(true);
                    count++;
                    setLoad(false);
                } else {
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setMsgravity('danger');
                    setLoad(false);
                }
            });
        } else {
            setLoad(false);
        }
    }

    const handleCloses = () => {
        if (count !== 0) {
            setActualise(!actualise);
        }
        count = 0;
        setShowAdd(false);
        setIsSave(false);
    };
    function setAddClient(val) {
        setAddclient(val);
        setShowAddclient(true);
    }

    function saveSortie(values, setSubmitting) {
        try {
            if (listeSelect.length === 0) {
                setMsg('Veuillez sélectionner des articles à livrer');
                setMsgravity('warning');
                setIsSave(true);
                return;
            }
            setLoad(true);
            setSubmitting(true);
            var formData = new FormData();
            formData.append('datesort', values.datesort);
            formData.append('demand_par', values.demand_par);
            formData.append('engin', values.engin);
            formData.append('clients_id', values.clients_id);
            formData.append('nobon', values.nobon);
            formData.append('codeart', JSON.stringify(listeSelect));
            formData.append('user_id', common.userid);
            Axios({
                url: id ? common.ipapi + '/api/sortie/update/' + id : common.ipapi + '/api/sortie/create',
                method: 'POST',
                headers: {
                    'Content-Type': 'form-data'
                },
                data: formData
            })
                .then((res) => {
                    if (res.status !== 200) {
                        setMsg('Veuillez vérifier votre connexion internet');
                        setMsgravity('info');
                        setIsSave(false);
                        setLoad(false);
                        setSubmitting(false);
                        return;
                    }
                    if (res.data.status === 1) {
                        setMsg(res.data.msg);
                        setMsgravity('info');
                        setIsSave(true);
                        setListeArticle([]);
                        setListeSelect([]);
                        getListeArticle();
                        count++;
                        setLoad(false);
                        setSubmitting(false);
                        if (id) {
                            setTimeout(handleCloses, 600);
                        }
                    } else {
                        if (res.data.msg == 23000) {
                            setMsg('Veuillez remplir tous les champs');
                        } else {
                            setMsg(res.data.msg);
                        }
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                        setSubmitting(false);
                    }
                })
                .catch((error) => {
                    setMsg('Une erreur à eu lieu lors de la création');
                    setIsSave(false);
                    setMsgravity('danger');
                    setLoad(false);
                    setSubmitting(false);
                });
        } catch (error) {
            setMsg('Veuillez vérifier votre connexion internet');
            setIsSave(true);
            setMsgravity('danger');
            setLoad(false);
            setSubmitting(false);
        }
    }

    const { demandeur, engin, nobon, datesort, client_id } = states;

    const handleOnChange = (selected) => {
        setCodeart(selected);

        if (selected.length > 0) {
            const lastSelectedItem = selected[selected.length - 1];
            lastSelectedItem.quantite = 1; // Or set a default quantity as needed

            // More efficient way to update listeSelect
            const newListeSelect = [...listeSelect];
            let itemExists = false;

            for (const item of newListeSelect) {
                if (item.article_id === lastSelectedItem.id) {
                    itemExists = true;
                    break; // Exit loop once found
                }
            }

            if (!itemExists) {
                newListeSelect.push({
                    id: 0, // Consider if this ID needs to be unique or generated differently
                    article_id: lastSelectedItem.id,
                    qrbarre: lastSelectedItem.qrbarre,
                    quantite: 1,
                    descriptionart: lastSelectedItem.descriptionartfr || lastSelectedItem.descriptionart,
                    prix: lastSelectedItem.prixvente
                });
                setListeSelect(newListeSelect);
                setCodeart([]); // Clear the typeahead input after adding
            } else {
                setIsSave(true);
                setMsgravity('warning');
                setMsg('Déjà ajouté à la liste');
                setCodeart([]); // Clear the typeahead input
            }

            // setFieldValue('codeart', []); // Clear the form field as well for better UX
        }
    };

    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>
            {addclient !== false ? (
                <AddClient
                    setShowAddclient={setShowAddclient}
                    show={showAddclient}
                    setIsSave={setIsSave}
                    isSave={false}
                    setClient={setClients}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                />
            ) : (
                <></>
            )}
            <Formik
                initialValues={{
                    demand_par: demandeur,
                    engin: engin,
                    nobon: nobon,
                    datesort: datesort,
                    clients_id: client_id,
                    user_id: common.userid,
                    codeart: listeSelect,
                    submit: null
                }}
                // enableReinitialize
                validationSchema={Yup.object().shape({
                    clients_id: Yup.number().typeError('Aucune correspondance').required(common.msg),
                    datesort: Yup.date().required(common.msg)
                })}
                onSubmit={async (values, { setSubmitting, isSubmitting }) => {
                    if (window.confirm('Voulez-vous confirmer cette action') === false) {
                        return;
                    }
                    saveSortie(values, setSubmitting);
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setFieldTouched }) => (
                    <Modal show={show} fullscreen={true}>
                        <div className="content">
                            <Form noValidate onSubmit={handleSubmit}>
                                {/*noValidate */}
                                <Modal.Header>
                                    {id ? 'MODIFIER UNE SORTIE DE STOCK' : 'FAIRE UNE SORTIE DE STOCK'}
                                    <Backspace onClick={handleCloses} color="red" size={30} />
                                </Modal.Header>
                                <Modal.Body>
                                    {qtesup ? (
                                        <Alert key="danger" transition="Fade" variant="danger">
                                            <TriangleHalf color="red" size={30} />
                                            {qtesupmsg}
                                        </Alert>
                                    ) : (
                                        <></>
                                    )}
                                    <div id="lightbody">
                                        <Row>
                                            <Col md={4}>
                                                <Card bg="light" border="dark">
                                                    <Card.Body>
                                                        <Card.Title>Entête de la facture </Card.Title>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                Nom client:
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <InputGroup>
                                                                    <Typeahead
                                                                        id="clients_id"
                                                                        multiple={false}
                                                                        onChange={(selected) => {
                                                                            const value = selected.length > 0 ? selected[0].id : 0;
                                                                            setFieldValue('clients_id', value);
                                                                        }}
                                                                        onInputChange={(text, event) => setFieldValue('clients_id', text)}
                                                                        onBlur={(e) => setFieldTouched('clients_id', true)}
                                                                        labelKey="raisonsocial"
                                                                        options={clients}
                                                                        selected={clients.slice(
                                                                            clients.findIndex(
                                                                                (element) => element.id === values.clients_id
                                                                            ),
                                                                            clients.findIndex(
                                                                                (element) => element.id === values.clients_id
                                                                            ) + 1
                                                                        )}
                                                                    />

                                                                    <OverlayTrigger
                                                                        overlay={
                                                                            <Tooltip id="tooltip-disabled">
                                                                                Ajouter un nouveau client
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <PlusCircle
                                                                            color="#51cbce"
                                                                            onClick={() => setAddClient()}
                                                                            size={35}
                                                                        />
                                                                    </OverlayTrigger>
                                                                </InputGroup>
                                                                {errors.clients_id && <div id="feedback">{errors.clients_id}</div>}
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                N° bon:
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <Form.Control
                                                                    id="nobon"
                                                                    type="text"
                                                                    placeholder="N° de bon n'est pas obligatoire"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    value={values.nobon}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                />
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                Date sortie:
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <Form.Control
                                                                    id="datesort"
                                                                    type="date"
                                                                    placeholder="Date sortie"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    required
                                                                    size="sm"
                                                                    value={values.datesort}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                />
                                                                {errors.datesort && <div id="feedback">{errors.datesort}</div>}
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                Demandé par:
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <Form.Control
                                                                    id="demand_par"
                                                                    type="text"
                                                                    placeholder="Demande par"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    value={values.demand_par}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                Autres:
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <Form.Control
                                                                    id="engin"
                                                                    type="text"
                                                                    placeholder="Ex: chassis, immatriculation, N° fiche"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    value={values.engin}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Veuillez saisir la personne qui demande cette sortie
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col md={8}>
                                                <Card bg="light" border="dark">
                                                    <Card.Body>
                                                        <Card.Title>Sélection des articles à livrer </Card.Title>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                Filtre (Model pièces):
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <Typeahead
                                                                    ref={typeaheadModel}
                                                                    id="my-typeahead-id"
                                                                    labelKey={(option) => `${option.libellefam}`}
                                                                    filterBy={['libellefam']}
                                                                    value={famille_id}
                                                                    options={familles}
                                                                    onChange={(cli) => setFamilleId(cli)}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                Sélection articles:
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <Typeahead
                                                                    id="my-typeahead-id"
                                                                    labelKey={(option) =>
                                                                        `${option.famille} > ${option.qrbarre} > ${
                                                                            option.descriptionartfr || option.descriptionart
                                                                        } > qte (${option.qtestock})`
                                                                    }
                                                                    filterBy={['descriptionartfr', 'descriptionart', 'qrbarre']}
                                                                    selected={codeart}
                                                                    options={articleListe}
                                                                    disabled={load}
                                                                    onChange={handleOnChange}
                                                                    onInputChange={(text) => setFieldValue('codeart', text)}
                                                                    onBlur={(e) => setFieldTouched('codeart', true)}
                                                                    // clearButton  // Add a clear button for better UX
                                                                    // highlightOnlyResult  // Consider if you want to highlight only the first result
                                                                    // minLength={2} // Set a minimum input length before suggestions appear
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Table columns={columns} data={listeSelect} />
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="ml-auto">
                                        <ButtonGroup size="sm">
                                            <Button variant="secondary" onClick={handleCloses}>
                                                <Backspace color="red" size={20} />
                                            </Button>
                                            <Button type="submit" variant="warning" disabled={isSubmitting}>
                                                {isSubmitting ? (
                                                    <Spinner animation="border" variant="success" />
                                                ) : (
                                                    <Save2Fill color="#807f7e" size={20} />
                                                )}
                                                Enregister
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Modal.Footer>
                            </Form>
                        </div>
                    </Modal>
                )}
            </Formik>
        </>
    );
}

export default AddSortie;
